:root {
  --header-bg_color: #973833;
  --header-color:#fff;
  --header-color_hover:  #FC706E;
  --balance-heading:#84c0b5c9;
  --balance-value:#f4f8fb;
  --profile-dropdown_color: #000;
  --profile-dropdown_color_hover: #ffffff;
  --profile-dropdown_bg_hover: #01483791;
  --login-btn-color:#fff;
  --login-btn-bg:#FC706E;
  --heading_2-color: #001A16;
  --logoName-bgColor:linear-gradient(to right, #84C0B5, #FC706E);
  --Home-page-bg: transparent ;

  --tab-link-color: #FC706E;
  --tab-border_color: #84C0B5;
  --tab-link-color-hover: #84C0B5;
  --tab-link-bgColor: #fff;
  --tab-bgColor: #fff;
  --tab-link-bgColor-hover: #84C0B5;
  --banner-color: #fff;

  --tab-content-bgColor:  #D0D3EF;
  --tab-content-boxShadow: 0 1px 1px #F6F3EA;
  --tab-content-left-bgColor: #84C0B5;
  --tab-content-input-color:#84C0B5;
  --tab-content-input-border:1px solid rgba(0, 0, 0, 0.15);
  --tab-content-border: none;

  --footer-border-color: #a74e49;
  --footer-border-link-color:#ffffff93;
  --footer-color:#ffffff93;
  --footer-contact-h2-color:#363636;
  --footer-img-shadow: 0px 1px 2px #00000036;
  --footer-socile-icon-bgColor: #a74e49;
  --footer-socile-icon-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(255, 255, 255, 0.1) 0px 0px 1px 0px;
  --footer-contact-img-bgColor: #f7ecafd3;
  

  --GrowYourBussionWrapper-bgColor: #fff;
  --GrowYourBussion-wrapper-img-bgColor:#84c0b5d7;
  --footer-conttant-box-bgColor: #fff;
  --footer-conttant-box-shadow: 0px 1px 20px #49494921;
  --unnamed-color-ffffff: #fff;

  --slick-active-color:#FC706E;
  --slick-dots-color: #fff;

  --section-gbColor-V1:#84c0b5c4;
  --section-gbColor-V2: transparent linear-gradient(250deg, #84C0B5 0%,#F7ECAF 100%) 0% 0% no-repeat padding-box;

  --blue: #1e90ff;
  --white: #ffffff;
}

